import React, { memo } from 'react'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { useInView } from 'react-intersection-observer'
import AppStoreDarkLargeSVG from '../icons/appStoreDarkLargeSVG'
import Logo from '../icons/logo'
import StarHalfSVG from '../images/half-star.svg'
import StarSVG from '../images/star.svg'
import ContactForm from './contactForm'
import ContactSVG from './illustrations/ContactSVG'

const GoogleReCaptchaProviderM = memo(GoogleReCaptchaProvider)

const Contact = ({ appStoreLink }) => {
  const { ref, inView } = useInView({
    threshold: 0,
  })
  return (
    <section id="contact" ref={ref}>
      <div className="flex flex-grow pt-10 jusity-center md:pt-16 xl:pt-20">
        <div className="hidden ml-auto md:flex md:flex-col md:justify-evenly md:w-1/2 max-w-half-layout">
          <div className="items-center justify-between hidden xl:justify-evenly md:flex md:px-16 lg:px-20">
            <div className="flex items-center">
              <Logo className="w-8 h-8 mr-4 min-w-8 min-h-8 md:min-w-12 md:w-12 md:h-12 md:min-h-12" />
              <div className="flex flex-col justify-center">
                <h3 className="mr-4 leading-4 font-title bold">VoxRec</h3>
                <div className="items-center hidden lg:flex ">
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img src={StarSVG} alt="star" className="w-4 h-4 mr-2px" />
                  <img
                    src={StarHalfSVG}
                    alt="star"
                    className="w-4 h-4 mr-2px"
                  />
                  <p className="ml-2 font-title">4.7</p>
                </div>
              </div>
            </div>
            <a
              href={appStoreLink}
              className="hidden ml-6 lg:block"
              target="_blank"
              rel="noreferrer"
            >
              <AppStoreDarkLargeSVG className="app-store-link" />
            </a>
          </div>
          <div className="mr-24 md:mr-12 lg:mr-16 xl:mr-24">
            <ContactSVG className="w-full h-full" />
          </div>
        </div>
        <div className="w-full px-4 md:w-1/2 max-w-half-layout lg:pr-20 xl:pr-44 lg:mr-auto">
          <div className="mb-12 lg:mb-16">
            <h2 className="mb-4 heading">Dillo con parole tue</h2>
            <p className="max-w-lg sub-heading">
              Non vediamo l'ora di leggere i tuoi commenti, curiosità o suggerimenti.
              Non essere timido, scrivici!
            </p>
          </div>
          {inView && (
            <GoogleReCaptchaProviderM
              reCaptchaKey="6LfdMQYbAAAAAPBOb1dDgmNtm0DoDCUf788kcEed"
              scriptProps={SCRIPT_PROPS}
            >
              <ContactForm />
            </GoogleReCaptchaProviderM>
          )}
        </div>
      </div>
    </section>
  )
}
export default Contact

const SCRIPT_PROPS = {
  defer: true,
}
