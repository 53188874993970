import React from 'react'
import AppStoreDarkLargeSVG from '../icons/appStoreDarkLargeSVG'

const Pricing = ({ appStoreLink }) => {
  return (
    <section id="pricing">
      <div className="pt-4 md:pt-12 layout-space">
        <div className="max-w-6xl pb-12 mx-auto lg:px-10 md:pb-20 xl:pb-24">
          <div className="mb-12 md:text-center md:mb-16">
            <h2 className="mb-4 heading">Prezzo</h2>
            <p className="md:max-w-xl sub-heading md:mx-auto">
              Il download di VoxRec è GRATUITO. Le funzionalità di registrazione sono completamente GRATIS. Offriamo abbonamenti mensili o opzioni a consumo per il servizio di dettatura vocale.
            </p>
          </div>

          <div className="md:flex md:flex-row-reverse md:items-center">
            <div className="text-center md:flex md:flex-wrap md:text-left xl:w-3/5 md:w-1/2 md:items-start">
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-8">
                <h5 className="point-title">Riproduzione</h5>
                <p className="small">
                  Segui chi parla e la discussione con l'evidenziazione visiva delle parole durante la riproduzione. Seleziona qualsiasi parola per avviare la riproduzione.
                </p>
              </div>
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-8">
                <h5 className="point-title">Formatta &amp; Modifica</h5>
                <p className="small">
                  Aggiungi appunti alle registrazioni, modifica le trascrizioni, formatta o sottolinea il testo direttamente sul tuo iPhone o iPad.
                </p>
              </div>
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-0">
                <h5 className="point-title">Segna un momento importante</h5>
                <p className="small">
                  Quando la discussione diventa importante, segna con un tag i momenti salienti nella registrazione. Riascoltali con un tocco.
                </p>
              </div>
              <div className="max-w-sm px-4 mx-auto mb-12 md:w-1/2 md:mb-0">
                <h5 className="point-title">Backup &amp; Sinc.</h5>
                <p className="small">
                  Dropbox, Google Drive o iCloud. Le tue registrazioni e le tue trascrizioni saranno archiviate in modo sicuro.
                </p>
              </div>
            </div>
            <div className="md:w-1/2 xl:w-2/5 card-background-image">
              <div className="z-10 max-w-sm px-6 py-8 mx-auto bg-white shadow-lg md:mx-0 md:px-10 md:py-12 rounded-xl bg-10">
                <div className="mb-4">
                  <p className="from">da</p>
                </div>
                <div className="flex items-end mb-4">
                  <h3 className="big-money">€0.07</h3>
                  <p className="mb-1 text-black md:text-3xl">
                    &nbsp;/&nbsp;minuto
                  </p>
                </div>
                <hr className="mb-2" />
                <h4 className="mb-6 text-accent">
                  30 minuti GRATIS{' '}
                  <span className="lg:hidden md:hidden text-accent">
                    di dettatura vocale
                  </span>
                </h4>
                <div className="md:justify-between md:flex md:items-center">
                  <a href={appStoreLink} target="_blank" rel="noreferrer">
                    <AppStoreDarkLargeSVG className="mb-4 app-store-link md:mb-0 md:mr-8" />
                  </a>
                  <p className="small">
                  Disponibile per iPhone, iPad, &amp; Apple Watch
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Pricing
